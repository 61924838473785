@import "../styles/index.scss";

.answers {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin-top: 60px;

    &-label {
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 20px;
    }

    &-questions {
        flex: 1;
        overflow-y: scroll;
        margin-bottom: 10px;
    }

    &-question {
        font-weight: 400;
        font-size: 22px;
        line-height: 120%;
        margin-bottom: 40px;
    }
}