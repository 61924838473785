@import "../../styles/index.scss";

.juryPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  margin-top: 40px;

  &-title {
    font-weight: 400;
    font-size: 35px;
    line-height: 43px;
    margin-bottom: 65px;
  }
}