@import "../../styles/index.scss";

.questionsPage {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding-top: 25px;
  overflow: hidden;

  &-title {
    color: $color-orange;
    font-size: 48px;
    font-weight: 900;
    line-height: 100%;
    margin-bottom: 50px;
  }

  &-questions {
    flex: 1;
    overflow-y: scroll;
    margin-bottom: 10px;
  }

  &-question {
    align-items: center;
    display: flex;
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;
    margin-bottom: 40px;
  }

  &-buttons {
    display: flex;
  }
}