@import "../styles/index.scss";

.inputArea {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  width: 100%;

  &-text {
    background-color: $color-white;
    border-radius: 10px;
    border: 1px solid $color-white;
    flex: 1;
    font-size: 20px;
    font-family: 'Open Sans';
    height: 40px;
    padding: 10px 10px 200px;
    width: 100%;
  }

  &-buttons {
    align-items: center;
    display: flex;
    margin-bottom: 25px;
  }

  &-select {
    background-color: $color-orange;
    border: 1px solid $color-orange;
    border-radius: 15px;
    color: $color-white;
    display: block;
    flex: 1;
    font-size: 20px;
    outline: 0;
    margin: 0 20px;
    padding: 10px;
  }
}