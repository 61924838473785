@import "../styles/index.scss";

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;

    &-column {
        overflow: hidden;
        &:nth-child(2) {
            min-width: 130px;
            text-align: right;
        }
    }

    &-label {
        font-size: 20px;
        line-height: 120%;
        margin-bottom: 10px;
        white-space: nowrap;
    }

    &-name, &-points {
        color: $color-orange;
        font-size: 48px;
        line-height: 100%;

        &--inner {
            color: $color-white;
        }
    }
}