@import "../styles/index.scss";

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 15px 15px 20px;
  gap: 5px;
  background-color: $color-orange;
  color: $color-white;
  border-radius: 15px;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  line-height: 22px;
  width: 215px;

  &:disabled {
    background-color: rgba($color-orange, 0.5);
  }

  &:last-child {
    margin-left: auto;
  }

  &--square {
    border-radius: 5px;
    gap: 0;
    width: auto;
    padding: 15px;
  }

  &-arrow {
    width: 18px;
    height: 18px;
  }
}