@import "../styles/index.scss";

.title {
  color: $color-orange;
  display: flex;
  flex-wrap: wrap;
  font-weight: 900;
  font-size: 110px;
  justify-content: center;
  line-height: 1;
  text-align: center;
  text-shadow: 0px 2px, 2px 0px, 2px 2px;

  span {
    margin: 0 10px;
  }

  &-white {
    color: $color-white;
  }
}