@import "../../styles/index.scss";

.answersByTeam {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 10px;

  &-answers {
    overflow-y: scroll;
  }

  &-teamAnswer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &-team {
    color: $color-orange;
    flex-basis: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    margin-bottom: 12px;
  }
}