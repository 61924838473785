@import "../../styles/index.scss";

.correctToggle {
  align-items: center;
  display: flex;

  &-icon {
    border-radius: 5px;
    fill: $color-white;
    width: 55px;
    height: 55px;

    &--correct {
      background-color: $color-correct;
      margin-right: 21px;
      padding: 9px;

      &:hover {
        background-color: $color-correct;
      }
    }

    &--wrong {
      background-color: $color-error;
      padding: 14px;

      &:hover {
        background-color: $color-error;
      }
    }

    &--unknown {
      background-color: $color-yellow;
      margin-right: 21px;
      padding: 14px;

      &:hover {
        background-color: $color-yellow;
      }
    }

    &--inactive {
      background-color: $color-blue;
    }
  }
}