@import "../styles/index.scss";

.main {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  // &-column {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   height: 100%;
  //   width: 50%;
  // }

  &-logo {
    width: 90px;
    height: 80px;
    margin-bottom: 30px;
  }
}

.loginForm {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  &-inputs {
    display: flex;
    flex-direction: column;
  }

  &-input {
    border-radius: 5px;
    font-size: 16px;
    margin-bottom: 10px;
    padding: 15px;
    width: 250px;
  }
}

.screen {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;

  &-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
  }

  &-logo {
    width: 130px;
    height: 120px;
  }

  &-question {
    flex-basis: 100%;
    font-weight: 700;
    font-size: 25px;
    line-height: 150%;
    text-align: center;
  }
}