@import "../styles/index.scss";

.ranking {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    margin: 40px 0 10px;
    // max-height: 290px;

    &-label {
        font-size: 20px;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 20px;
    }

    &-teams {
        flex: 1;
        overflow-y: scroll;
        width: 100%;
    }

    &-team {
        display: flex;
        justify-content: space-between;
    }

    &-line {
        flex: 1;
        border-bottom: 1px solid $color-orange;
        margin: 2px 5px;
    }

    &-select {
        background-color: $color-orange;
        border: 1px solid $color-orange;
        border-radius: 5px;
        color: $color-white;
        display: block;
        font-size: 20px;
        outline: 0;
        margin: 0 auto 20px;
        padding: 10px;
        width: 200px;
    }

    &-difficulty {
        text-align: right;
        width: 260px;
    }
}