@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import "./styles/index.scss";

html, body, #root {
  height: 100%;
}

body {
  background: linear-gradient(65.21deg, #00317A 29.35%, rgba(0,49,122, 0.9));
  color: $color-white;
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  & * {
    box-sizing: border-box;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

input, textarea {
  outline: none;
}

textarea {
  resize: none;
}
