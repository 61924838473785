@import "../styles/index.scss";

.answer {
    font-weight: 400;
    font-size: 22px;
    line-height: 120%;

    &--correct {
        color: $color-correct;
    }

    &--wrong {
        color: $color-error;
    }
}

.answersByTeam {
    .answer {
        &--unknown {
            color: $color-yellow;
        }
    }
}