@import "../styles/index.scss";

.countdown {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;

  &-label {
    flex-basis: 100%;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
  }

  &-time {
    background-color: $color-white;
    border-radius: 20px;
    color: $color-orange;
    margin: 15px;
    max-height: 290px;
    max-width: calc(50% - 10px);
    padding: 10px;
    width: 170px;

    &--right {
      border: 4px solid $color-error;
    }
  }

  &-number {
    font-weight: 800;
    font-size: 90px;
    line-height: 100%;
  }
}