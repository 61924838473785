@import "./styles";

.App {
  // background: $color-bg;
  background-image: url('./assets/images/bg.png');
  background-repeat: no-repeat;
  background-position: 50px 50px;
  background-size: 500px 800px;
  height: 100%;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  padding: 25px 25px 80px;
}
